<template>
  <div class="groupBox">
    <div class="groups">
      <div class="tit">团 队</div>
      <div class="desc">
        我们是一群思维活跃又能执行落地的团队。虽然我们的成员分布在北京、广州、美国不同的城市。
        但每一个发光的个体，一起做一件事的时候，就散发出了巨大的能量。
      </div>
      <div class="list">
        <div class="item">
          <div class="itemTit">北京</div>
          <div class="itemImg">
            <img
              src="https://video.wahool.com/b_website/about/address1.png"
              alt=""
            />
          </div>
        </div>
        <div class="item">
          <div class="itemTit">广州</div>
          <div class="itemImg">
            <img
              src="https://video.wahool.com/b_website/about/address2.png"
              alt=""
            />
          </div>
        </div>
        <div class="item">
          <div class="itemTit">纽约</div>
          <div class="itemImg">
            <img
              src="https://video.wahool.com/b_website/about/address3.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup></script>
<style lang="less" scoped>
.groupBox {
  font-family: 'Lantinghei SC';
  width: 100%;
  background: rgba(237, 237, 237, 0.5);
  padding: 100px 0 266px 0;
  display: flex;
  justify-content: center;
  .groups {
    width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .tit {
      color: #111111;
      font-weight: 700;
      font-size: 40px;
      line-height: 56px;
      text-align: center;
    }
    .desc {
      max-width: 800px;
      margin-top: 40px;
      font-size: 20px;
      line-height: 28px;
      color: #666666;
    }
    .list {
      margin-top: 80px;

      display: flex;
      justify-content: space-between;
      align-items: center;
      .item {
        .itemTit {
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;
        }
        .itemImg {
          margin-top: 40px;
          overflow: hidden;
          img {
            width: 350px;
            height: 190px;
            display: flex;
          }
        }
      }
    }
  }
}
.itemImg:hover img {
  transform: scale(1.8);
}
@media screen and(min-width:1200px) and(max-width:1400px) {
  .list {
    width: 100%;
    padding: 0 3%;
  }
}
@media screen and(min-width:1400px) {
  .list {
    width: 1200px;
  }
}
</style>
