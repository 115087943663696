<template>
  <div class="describeBox">
    <div class="textBox">
      <div class="item">
        <div class="desc">
          Wahool成立于2020年，以Amazon Live
          （亚马逊直播）切入，成功搭建红人输送管道。通过整合供应链资源，Wahool可提供包括选品、营销、品控、售后等在内的一站式数字化服务，在商家端（品牌方和工厂）、平台端与红人端之间基本形成了较为完整的业务生态闭环，正在从亚马逊TOP级红人服务平台扩展成为包括TikTok、Instagram和Facebook在内的全域红人直播选品服务平台。
        </div>
      </div>
      <div class="item">
        <div class="tit">愿 景</div>
        <div class="desc">
          我们努力成为时尚领域Dropship平台。提供一种简单的方式，向全球客户销售中国制造的高质量的时尚产品。Wahool设想了这样一个世界，每个人都有机会获得工具和技术，在时尚行业书写自己的成功故事。
        </div>
      </div>
      <div class="item">
        <div class="tit">使 命</div>
        <div class="desc">
          用我们的技术为时尚创业者们提供一个简单的平台来建立他们梦想的网上商店。我们的平台链接了供应商和时尚创业者，同时处理所有的物流、退货和客户服务。
          我们致力于以最实惠的价格，提供高质量、受欢迎的时尚产品，能够始终如一地为我们的时尚内容创造者、时尚创业者和中国企业家客户提供价值，并建立长期的信任。
          我们努力提供时尚领域最高质量的Dropship服务，我们的合作客户和创业者可以专注于销售，以扩大自己的业务。
        </div>
      </div>
    </div>
  </div>
</template>
<script setup></script>
<style lang="less" scoped>
.describeBox {
  width: 100%;
  display: flex;
  font-family: 'Lantinghei SC';
  justify-content: center;
  background: rgba(237, 237, 237, 0.5);
  .textBox {
    width: 1000px;
    padding: 100px 0;
    .item {
      .tit {
        text-align: center;
        margin-bottom: 40px;
        font-weight: 700;
        font-size: 40px;
        line-height: 56px;
        color: #111111;
      }
      .desc {
        font-size: 20px;
        line-height: 28px;
        color: #666666;
        margin-bottom: 60px;
        text-indent: 2em;
      }
    }
  }
}
</style>
