<template>
  <div class="cardBox">
    <div class="conBox">
      <div class="tit">价值观</div>
      <div class="list">
        <div class="listItem">
          <div class="top">
            <img
              src="https://video.wahool.com/b_website/about/aboutIcon1.png"
              alt=""
            />
          </div>
          <div class="conText">己任</div>
          <div class="desc">
            秉承着一个团队精神，解决客户的和产品端的问题，无论这个工作是不是我的职责所在。
          </div>
        </div>
        <div class="listItem">
          <div class="top">
            <img
              src="https://video.wahool.com/b_website/about/aboutIcon2.png"
              alt=""
            />
          </div>
          <div class="conText">真诚</div>
          <div class="desc">
            目光长远，创造长期价值。信任团队，同时拥有发表自己见解和意见的空间。
          </div>
        </div>
        <div class="listItem">
          <div class="top">
            <img
              src="https://video.wahool.com/b_website/about/aboutIcon3.png"
              alt=""
            />
          </div>
          <div class="conText">深耕</div>
          <div class="desc">
            刨根问底，深度挖掘。不为了解决一时的问题，而是发掘问题产生的底层原因，并寻求最好的解决方案。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup></script>
<style lang="less" scoped>
.cardBox {
  width: 100%;
  padding: 100px 0;
  display: flex;
  justify-content: center;
  font-family: 'Lantinghei SC';
  .conBox {
    width: 1120px;
    .tit {
      font-weight: 700;
      font-size: 40px;
      line-height: 56px;
      color: #111111;
      text-align: center;
    }
    .list {
      margin-top: 80px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      .listItem {
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .top {
          background: #fb5d43;
          border-radius: 40px;
          width: 220px;
          height: 220px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 156px;
            height: 156px;
            display: flex;
          }
        }
        .conText {
          margin: 60px 0 30px 0;
          font-weight: 500;
          font-size: 24px;
          line-height: 33px;
          color: #fb5d43;
        }
        .desc {
          font-size: 20px;
          line-height: 28px;
          color: #111111;
        }
      }
    }
  }
}
</style>
